<template>
  <div class="c-time-length" :class="`c-time-length--${color}`">{{time}}</div>
</template>
<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'TimeLength',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const calcTime = (duration) => {
      const min = Math.floor(duration / 60);
      const sec = duration % 60;
      return `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`;
    };

    const timeLength = computed(() => {
      if (props.data?.tracks && props.data?.tracks[0]) {
        const { duration } = props.data?.tracks[0];
        return calcTime(duration);
      }

      if (props.data?.track?.duration) {
        const { duration } = props.data?.track;
        return calcTime(duration);
      }

      if (props.data?.duration) {
        const { duration } = props.data;
        return calcTime(duration);
      }

      if (props.data?.description) {
        const { description } = props.data;
        return calcTime(description);
      }

      return '';
    });

    const time = computed(() => {
      if (props.data?.accountPlaylistContentUid && props.data?.type === 'Albums') {
        return '-';
      }

      return `${props.data.length || ''} ${timeLength.value}`;
    });

    return {
      time,
      timeLength,
      // timeLength,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-time-length {
  @include font-style($c-assist4, 14, 400, 0.8px, 26px);
}

@media screen and (min-width: $sm-pc) {
  .c-time-length {
    color: $c-black;

    &--white {
      color: $c-white;
    }
  }
}
</style>
